@import "../../../shared/styles/index.scss";

.mint__warning {
  @include setFlex($direction: column);
  // margin: .8rem;
  padding: 0.8rem;
  background: #c1680014;
  border: 10px solid #c1680040;
  color: $warning-color;
  border-radius: $border-radius;

  // responsive
  &.responsive-md {
    font-size: 0.8rem;
  }

  h5 {
    color: $warning-color;
    font-weight: 600;
  }

  ul {
    li {
      text-align: start;
      font-size: 0.75rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $warning-color;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
