@import "./index.scss";

.coins {
  margin-top: 1rem;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  & > .responsive-xs {
    h4 {
      font-size: 0.9rem;
      padding: 0 1rem 0.6rem 0.5rem;
    }
  }

  & > .responsive-sm {
    h4 {
      font-size: 1rem;
      padding: 0 1rem 0.6rem 0.5rem;
    }
  }

  & > .responsive-md {
    h4 {
      font-size: 1.1rem;
      padding: 0 1rem 0.6rem 0.5rem;
    }
  }

  h4 {
    font-weight: 800;
    text-align: start;
    color: $main-color;
    padding: 0 1rem 0.6rem 1rem;
    margin-bottom: 0;
  }

  .coins__grid {
    .coins__header.coins__row {
      background-color: $main-bg;

      ul {
        li {
          font-size: 0.8rem;
          font-weight: 600;
          color: #000;
        }
      }
    }

    .coin-actions {
      text-align: center;

      button {
        margin-right: 0.5rem;
        padding: 0.25rem 1.2rem;
        font-weight: 600;
        border-radius: $border-radius;
        background: $main-grey-bg;

        &:first-child {
          a {
            color: #fff;
          }
        }

        &:first-child {
          color: #fff;
        }

        &:last-child {
          background: transparent;
          border: $main-grey-border;

          a {
            color: $main-grey-color;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        &:last-child,
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}
