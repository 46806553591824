.registration-wrap {
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-form-item-explain-error {
  align-self: baseline;
}
div[role="alert"] {
  text-align: justify;
}

.agreement-check-wrap {
  height: 40px;

  .ant-form-item-explain-error {
    display: none;
  }
}
