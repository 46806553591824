@import '../../../shared/styles/index.scss';

$input-preset-width: 50px;

.redeem__form {
    margin-top: 1rem;
    padding: 1rem;

    .form__col {
        &:first-child {
            margin-right: 1rem;
        }
    }

    .grey {
        background-color: $grey-bg !important;
    }

    form {
        .form__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .input__field {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                @include setFlex($direction: column);

                label {
                    font-size: .8rem;
                    font-weight: 600;
                }

                span {
                    position: absolute;
                    top: 24px;
                    left: 0;
                    height: 40px;
                    width: $input-preset-width;
                    border-radius: $border-radius 0 0 $border-radius;
                    display: grid;
                    place-items: center;
                    background-color: #EFEFEF;
                }

                input {
                    border: none;
                    outline: none;
                    background-color: #F8F8F8;
                    border: 1px solid #E2E2E2;
                    border-radius: $border-radius;
                    padding: .5rem .8rem;
                    width: 100%;
                    // margin-top: .25rem;
                }

                &.container {
                    input {
                        width: calc(100% - 50px);
                        margin-left: 45px;
                        background-color: #fff !important;
                    }

                    span.dollar {
                        font-weight: 600;
                        font-size: 1rem;
                    }
                }

                .ant-select.ant-select-single.ant-select-show-arrow {
                    width: 100% !important;
                }

                .ant-select-selection-item {
                    display: inline-block;
                    text-align: start;
                    color: $placeholder-grey-color;
                    font-size: .9rem;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    padding: .25rem .8rem;
                    margin-top: .25rem;
                }
            }
        }

        .form__conclusion {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid $grey-bg;

            .form__grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    button {
        margin-right: .5rem;
        padding: .6rem 1.5rem;
        font-weight: 600;
        font-size: .8rem;
        border-radius: $border-radius;
        background: $main-color;
        color: #fff;
        display: block;
        margin-top: 1rem;
        margin-left: auto;
    }
}