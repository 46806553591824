@import '../../shared/styles/index.scss';

.sidebar {
    // flex: .15;
    height: 100%;
    background-color: $black-bg;
    z-index: 1000;
    overflow: hidden;
    width: 255px;

    h5 {
        color: #fff;
        text-align: start;
        opacity: .5;
        padding: .5rem 1rem;
        font-weight: 800;
    }

    .logo {
        z-index: 1000;
        position: relative;
        width: 100%;
        height: 40px;
        padding: 1rem 1rem 3rem 1rem;
        border-bottom: $border;


        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-right: auto;
            display: block;
            mix-blend-mode: normal;
        }
    }

    ul {
        display: flex;
        flex-direction: column;

        li {
            a {
                padding: 0 .5rem;
                border-radius: $border-radius;
                @include setFlex($align: center);

                img {
                    opacity: .6;
                    width: 15px;
                    margin-right: .5rem;
                    object-fit: contain;
                }

                span {
                    color: #fff;
                    font-size: .8rem;
                    margin-top: .2rem;
                    width: 100%;
                }

                &.active-link {
                    background: #363636;

                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ant-layout-sider-children {
        @include setFlex($direction: column);

        .ant-menu.ant-menu-dark {
            flex: 1;
        }

        .sidebar-userInfo {
            // padding: 1rem;
            width: 100%;
            padding: .5rem;
            @include setFlex($direction: column);


            h4 {
                font-weight: 600;
                margin-bottom: 0;
                opacity: .35;
                color: #fff;
                margin-bottom: 1rem;
            }


            .sidebar-userInfo-content {
                @include setFlex();
                cursor: pointer;
                width: 100%;
                padding: .5rem .5rem;
                border-radius: $border-radius;

                &.active {
                    background: $grey-bg;
                }

                .userData {
                    margin: 0 .5rem;
                    @include setFlex($direction: column, $align: flex-start, $flex:1);

                    &>* {
                        color: #fff;
                        opacity: .65;
                        text-align: start;
                    }

                    h5 {
                        font-weight: 800;
                        margin-bottom: 0;
                        padding: 0;
                        margin: 0;
                    }

                    p {
                        font-size: .65rem;
                    }
                }

                .userInfo-options {
                    button {
                        padding: 0;
                        background-color: transparent;
                        border: none;
                    }

                    // Styles of its entire popup is in the bottom of the file
                }
            }
        }

        .sidebar-logout {
            width: 100%;
            padding: .5rem;

            button {
                background: $grey-btn;
                border: none;
                padding: 1.4rem 1rem;
                display: flex;
                align-items: center;
                text-align: start;
                border-radius: 6px;
                width: 90%;
                margin: auto;

                span {
                    color: #fff;
                    opacity: .85;
                    margin-left: .5rem;
                }
            }
        }
    }

}

// antd
.ant-modal-mask {
    background-color: rgba(0, 0, 0, .85) !important;
}

.ant-modal {
    &.view-profile-modal {
        .ant-modal-content {
            background-color: transparent;

            button {
                position: absolute;
                left: 15px;
                top: 25px;
                border-left: 3px solid $main-color;
            }
        }
    }

    .ant-modal-content {
        border-radius: $border-radius;
        overflow: hidden;
        width: 370px;
        padding: 2rem .5rem;

        button {
            width: 140px !important;
        }


        .ant-modal-close-x {
            display: none;
        }

        .ant-modal-title,
        .ant-modal-body,
        .ant-modal-footer {
            text-align: center;
        }

        .ant-modal-title {
            font-weight: 600;
            border-bottom: none;
            padding: 0;
            @include setFlex($direction: column, $justify: center, $align: center);

            img {
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
            }
        }

        .ant-modal-header {
            padding: 0;
            border-bottom: none;
        }

        .ant-modal-body {
            font-weight: 500;
            padding: 0;
            margin: .8rem 0;
        }


        .ant-modal-footer {
            border-top: none;

            button {
                padding: .5rem 2rem;
                max-width: 150px;
                width: 150px;
                height: 40px;
                font-weight: 600;
                border-radius: $border-radius;
            }

            .ant-btn {

                &:not(:last-child) {
                    margin-right: .5rem;
                }
            }

            .ant-btn.ant-btn-primary {
                background-color: $danger-bg;
                border: none;
                outline: none;
            }
        }
    }
}

.ant-menu-item {
    img {
        width: 15px;
        object-fit: contain;
    }
}