.main-container {
  .admin-sidebar {
    &__profile {
      margin-top: auto;
      margin-bottom: 30px;
    }
    height: 100vh;
    background-color: #1a1c1e;
    width: 300px;
    &__logo {
      padding: 15px;
      img {
        height: 35px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        cursor: pointer;
      }
    }
    &__user {
      padding: 7px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      backdrop-filter: blur(3px) saturate(180%);
      -webkit-backdrop-filter: blur(3px) saturate(180%);
      background-color: rgba(69, 69, 69, 0.79);

      border-radius: 5px;
      margin-bottom: 20px;
    }

    &__name {
      color: #fff;
      font-size: 15px;
      word-break: break-all;
    }
    &__email {
      color: #fff;
      word-break: break-all;
      font-size: 14px;
      opacity: 0.7;
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
    hr {
      margin: 1px 0;
      border: 0.3px solid rgb(244 242 242 / 8%);
    }
    &__header {
      color: #8d8e8f;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
      opacity: 0.8;
      margin-top: 10px;
    }
    &__items {
      position: relative;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    &__overlay {
      position: absolute;
      backdrop-filter: blur(3px) saturate(180%);
      -webkit-backdrop-filter: blur(3px) saturate(180%);
      background-color: rgba(161, 161, 161, 0.44);
      transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1) 0.1s;
      border-radius: 5px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 69px) !important;
      padding: 0 30px;
    }
    &__logout {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      backdrop-filter: blur(0px) saturate(180%);
      width: 170px;
      margin: auto;
      -webkit-backdrop-filter: blur(0px) saturate(180%);
      background-color: rgba(34, 34, 34, 0.82);
      border-radius: 5px;
      padding: 8px;
      img {
        height: 15px;
        margin-right: 10px;
      }
      p {
        color: #fff;
        font-size: 15px;
      }
    }
    &__item {
      color: #d2d2d3;
      border-bottom: 0.3px solid rgb(244 242 242 / 8%);
      a {
        padding: 20px 0;
        position: relative;
        z-index: 11;
        display: block;
        &:link,
        &:visited,
        &:active {
          color: inherit;
          text-decoration: none;
          font-size: 14px;
          font-weight: bold;
        }
        img {
          margin-right: 15px;
          height: 17px;
          opacity: 0.7;
        }
        &.active-link {
          color: #fff !important;
          opacity: 0.9;
          img {
            opacity: 1 !important;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  section.main {
    width: calc(100% - 300px);
  }
}
