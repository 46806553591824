// global variables
$main-color: #0054b8;
$light-main-color-bg: rgba(0, 84, 184, 0.1);
$grey-color: #757575;
$grey-bg: #323435;
$grey-btn: #27292a;
$border: 1px solid #323435;
$warning-color: #c16800;
$warning-bg: #c1680014;
$warning-border: 1px solid #c1680040;
$light-grey: #ebebeb;
$black-bg: #1a1c1e;
$white-bg: #f2f2f2;
$black-color: #303030;
$red-color: #c81500;
$danger-bg: #cd2c2b;
$success-bg: #269a43;
$main-bg: #ececec;
$main-grey-bg: #1a1c1e;
$main-grey-color: #1a1c1e;
$main-grey-border: 1px solid #1a1c1e;
$secondary-grey-color: #323435;
$placeholder-grey-color: #606060;
$green-color: #00a27b;
$border-radius: 5px;
$box-shadow: 3px 6px 10px #c1c1c129;

// global mixins
@mixin setFlex(
  $justify: flex-start,
  $align: flex-start,
  $direction: row,
  $wrap: no-wrap,
  $flex: 0 1 auto
) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
  flex: $flex;
}

// global styles
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

// customized scrollbar styles
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* scrollable container with now scrollbar */
.scrollable {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

body {
  background: #ececec;
}

.main-container {
  min-height: 100vh;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .main {
    // height: 100vh;
    // margin-top: 3.5rem;
    background-color: #ececec;
    overflow-x: hidden;
    @include setFlex($direction: column, $justify: flex-start);

    & > section {
      flex: 1;
      margin: 0.25rem auto 0;
    }

    & > *:not(header) {
      margin-top: 1rem;

      // opacity: 0;
      // animation: fadeIn .25s ease forwards;

      & > *:first-child {
        margin-top: 0;
        padding-top: 0;

        & > *:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Shared Classes */
.container-fluid {
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: auto;
}

.responsive-md {
  &.container {
    width: 92vw;
    margin: auto;

    &:not(.main-header-nav) {
      margin: 0.8rem auto !important;
      width: 92%;
    }

    &.dashboard {
      width: 100%;
    }

    & > div {
      width: 100%;
    }
  }
}

/* Elements Tags */
ul {
  list-style: none;
  margin: 0;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

/* Input Field */
.input__field {
  label {
    font-size: 0.8rem;
    font-weight: 600;
  }

  input {
    border: none;
    outline: none;
    background-color: #f8f8f8;
    border: 1px solid #e2e2e2;
    border-radius: $border-radius;
    padding: 0.5rem 0.8rem;
    width: 100%;
    // margin-top: .25rem;
  }
}

/* Custom classes */

// container heading
.container-heading {
  color: $main-color;
  font-weight: 600;
  margin-bottom: 0rem;
  text-align: start;
}

// form heading
.form-heading {
  h3 {
    color: $main-grey-color;
    font-weight: 800;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.85rem;
    font-weight: 600;
    text-align: start;
    color: #505050;
  }
}

// black button
.btn-black {
  background-color: $black-bg;
  color: #fff;
  border-radius: $border-radius;
  padding: 0.5rem 2.6rem;
  font-weight: 600;

  &.lg {
    width: 140px;
    height: 40px;
  }
}

/* ANTD overriding */

.ant-anchor-ink::before {
  display: none !important;
}

.ant-anchor-wrapper {
  padding-left: 0;
}

.ant-menu.ant-menu-dark,
.ant-layout-sider,
.ant-layout-sider-dark {
  background: $black-bg;
}

.ant-layout-sider,
.ant-layout-sider-dark {
  min-width: 250px !important;
}

.ant-menu.ant-menu-dark {
  padding: 0.6rem;
}

.ant-menu.ant-menu-dark li {
  height: 60px !important;
  font-weight: 600;
  line-height: 60px;
  padding: 0;
  margin: 0 !important;

  &:not(:first-child) {
    border-top: $border;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: $grey-bg;
  border-radius: $border-radius;
  box-shadow: 2px 5px 10px #1e1e1e29;
}

.ant-menu-inline .ant-menu-item {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ant-select-selection-placeholder {
  text-align: start;
}

thead tr th.ant-table-cell {
  background-color: $main-bg;
}

// NOTE: THESE UPDATES FOR THE RESPONSIVE VIEW MAY CAUSE SOME CONFLICTS WITH THE MAIN VIEW
.ant-col.ant-form-item-control {
  width: 100%;
  @include setFlex($direction: column);
}

.ant-form-item-control-input {
  width: 100%;
}

.ant-input.ant-input-disabled {
  color: #000;
}
