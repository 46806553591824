@import './KycWarningBox.scss';
@import './KycForm.scss';

@import './shared.scss';

.kyc-container {
    width: 100%;
    height: 100%;
}

.kyc {
    display: grid;
    place-items: center;
    height: 90vh;
    max-width: 90%;


    .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-xs-offset-0.ant-col-sm-16.ant-col-sm-offset-8 {
        margin: 0;

        .ant-form-item-control-input {
            text-align: start;
        }
    }
}

// antd modal
.kyc-submit-success {
    .ant-modal-footer {
        .ant-btn {
            &:not(:last-child) {
                display: none;
            }
        }

        .ant-btn.ant-btn-primary {
            background-color: $success-bg !important;
        }
    }
}