.bank-details-wrap {
  margin-top: 120px;
  height: 100%;

  @media (max-width: 850px) {
    padding: 0 16px;
    margin-top: 58px;
  }

  .bank-details-header {
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;

    @media (max-width: 850px) {
      display: block;
      width: 100%;
      text-align: left;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.02em;
      color: #2058e5;
      margin-bottom: 17px;
    }
  }

  .bank-details-header-desc {
    @media (max-width: 850px) {
      font-size: 16px;
      line-height: 24px;
      color: #7e838d;
      text-align: left;
      margin-bottom: 54px;
    }
  }

  .bank-details-btn-col {
    width: 100%;
    padding: 0 4.166666%;

    @media (max-width: 850px) {
      padding: 0;
    }

    .ant-col-14 {
      max-width: 100%;
    }

    .ant-row-space-between {
      justify-content: center;

      @media (max-width: 850px) {
        justify-content: space-between;
      }

      .ant-col {
        padding: 0 20px;

        @media (max-width: 850px) {
          padding: 0;
        }
      }
    }
  }

  .bank-details-col {
    padding: 0 4.166666%;

    @media (max-width: 850px) {
      padding: 0;
      flex: 0 0 100%;
      max-width: 100%;

      .ant-row {
        flex-direction: column;
      }

      .ant-col-10 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: left;
      }

      .ant-col-14 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: left;
      }
    }
  }

  .bank-form-row {
    margin-bottom: 24px;
  }

  .ant-divider-inner-text {
    @media (max-width: 850px) {
      padding: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    @media (max-width: 850px) {
      display: none;
    }
  }
}
