@import "./index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.add-wallet {
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 1rem;
  opacity: 0.9;

  .wallet-info {
    margin-top: 1rem;

    h4 {
      margin-bottom: 0.5rem;
      padding: 0;
      text-align: start;
      font-weight: 600;
    }

    input {
      margin: 0 !important;
    }
  }

  form {
    .form-container {
      .title-label {
        display: flex;
        margin-bottom: 10px;
        font-size: 13px;
        color: #303030;
        font-weight: 600;
      }
      .input__field {
        width: 100%;
        overflow: hidden;
        @include setFlex($align: center, $justify: space-between);

        .ant-row.ant-form-item {
          &:first-child {
            flex: 1;
          }
        }

        .btn {
          flex: 0.3;
          overflow: hidden;
          border-radius: 0 $border-radius $border-radius 0;
          padding: 0;
          button {
            background: #27292a;
            color: #fff;
            border: none;
            outline: none;
            width: 100%;
            height: 42px;
            flex: 0.3;
            margin: 0;
            border-radius: 0 $border-radius $border-radius 0;
          }
        }

        input {
          border-radius: $border-radius 0 0 $border-radius;
        }
      }
    }
  }
}

// antd modal
.wallet-alert {
  h3 {
    font-weight: 600;
  }

  .ant-modal-footer {
    .ant-btn {
      &:not(:last-child) {
        display: none;
      }
    }

    .ant-btn.ant-btn-primary {
      background-color: $main-color !important;
    }
  }
}
