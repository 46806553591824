@import "./shared.scss";

.kyb {
  margin: 1rem auto 0 !important;
  background: #fff;
  min-height: 600px;
  max-height: 100vh;

  .container-heading {
    margin-bottom: 1rem;
    font-size: 15px;
  }

  .kyb-form {
    .kyb-heading {
      p {
        font-size: 0.9rem;
        font-weight: 600;
        text-align: start;
        color: #323435;
      }
    }

    .steps-indicator {
      position: relative;
      margin: 2rem 0;
      padding: 1rem 0;
      border-bottom: 2px solid #eaeaea;
      @include setFlex($align: center, $justify: space-between);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background: #eaeaea;
      }

      span {
        padding: 0.25rem 0.8rem;
        border-radius: $border-radius;
        font-size: 1rem;
        font-weight: 600;
        z-index: 2;
        background-color: #eaeaea;

        &.active {
          color: #fff;
          background-color: $main-color;
        }
      }
    }

    label {
      white-space: nowrap;
      width: 100%;
    }
  }
}

.ant-select-selection-item {
  text-align: start;
  font-weight: 600;
}

.ant-select.ant-select-single.ant-select-show-arrow {
  &::after {
    font-weight: 800;
    color: #000;
  }
}
