@import "./index.scss";

.recent__transactions {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 0.75rem;
  height: 180px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  background-color: #fff;

  .transactions__header {
    h3 {
      color: $main-color;
      text-align: start;
      font-weight: 700;
    }
  }

  .transactions__content {
    margin-top: 0.3rem;

    .transactions__grid {
      .row {
        padding: 0.25rem 0.8rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .col {
          position: relative;
          @include setFlex($direction: column);

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }

          &::before {
            content: "";
            position: absolute;
            left: -0.9rem;
            top: 0.4rem;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $grey-bg;
            opacity: 0.3;
          }

          span {
            font-weight: 600;

            &:first-child {
              font-size: 0.8rem;
              opacity: 0.8;
            }

            &:last-child {
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
