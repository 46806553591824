@import "./AddYourWallet.scss";
@import "./Coins.scss";
@import "./RecentTransactions.scss";
@import "./WalletOverview.scss";
@import "./DashboardActionsModal.scss";

@import "./index.scss";

.dashboard {
  position: relative;

  table {
    thead.ant-table-thead {
      background: #f1f1f1;

      tr {
        white-space: nowrap;

        th {
          font-size: 0.9rem;
          font-weight: 600;
          background: transparent;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 0.85rem;
          font-weight: 600;
          box-shadow: 3px 6px 10px #c1c1c129;
        }
      }
    }
  }

  &.responsive-md {
    margin: 1rem auto 0;
    width: 100%;
    padding: 0 1rem;
    // background-color: blue;

    .ant-table-tbody {
      background-color: #fff;

      td {
        white-space: nowrap;
      }
    }

    .ant-spin-nested-loading {
      width: 100%;
    }

    .ant-table {
      overflow-x: auto;

      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
    }

    @include setFlex($direction: column, $justify: flex-start);

    .dashboard-bottom {
      flex: 1;
      width: 100%;
      // background-color: #080;
    }
  }

  .dashboard-top {
    @include setFlex($direction: column);

    &.responsive-md {
      width: 100%;

      .mainContent {
        height: initial;
        width: 100%;
        @include setFlex($direction: column);

        & > div {
          height: 100%;
          max-height: 100%;
          width: 100%;
          max-width: 100%;

          &:first-child {
            margin-bottom: 1rem;
            background: #fff !important;
          }
        }
      }
    }

    .mainContent {
      height: 205px;
      @include setFlex($align: center, $justify: space-between);

      & > div {
        width: 49%;
        height: 100%;
        max-height: 100%;
      }
    }

    & > * {
      width: 100%;
    }
  }
}

.dashboard-actions-modal {
  .ant-modal-content {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    transform-origin: top;
    border-radius: $border-radius $border-radius 0 0;
    background-color: #fff;

    .ant-modal-close-x {
      display: block !important;
    }

    .ant-modal-footer {
      @include setFlex($direction: column);

      button,
      .ant-btn.ant-btn-primary {
        background: transparent !important;
        outline: none;
        border: none;
        color: #000;
        padding: 0;
        font-weight: 500 !important;
        font-size: 1.2rem;
        max-width: fit-content !important;
      }
    }
  }
}
