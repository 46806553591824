.acc-type-wrap,
.acc-type-wrap .ant-radio-group {
  width: 100%;
  label {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      margin: 0;
      max-width: 50%;
    }
  }
}

.acc-type-wrap .ant-radio-group {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .ant-radio {
    position: absolute;
    opacity: 0;
  }
  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;
    border: 1px solid transparentize(grey, 0.99);
    width: 100px;
    height: 100px;
    background-color: transparentize(grey, 0.8);
    border-radius: 50%;
  }
  p:first-of-type {
    text-align: center;
    font: {
      size: 20px;
      weight: bold;
    }
    margin-bottom: 20px;
  }
  p:last-of-type {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }
  button {
    margin: auto;
  }

  .ant-radio-wrapper-checked {
    .icon-wrap {
      border: 1px solid #2058e5;
      background-color: transparentize(#2058e5, 0.8);
    }
    p {
      color: #2058e5;
    }
  }
}
