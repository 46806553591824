@import "../../../shared/styles/index.scss";

// antd modal
.email-sent-modal {
  position: relative;

  .ant-modal-content {
    max-height: 280px;

    p {
      span {
        font-weight: 600;
      }
    }

    .message-footer {
      margin-top: 2rem;

      a {
        margin-left: 0.25rem;
        color: $main-color;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  img.close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    cursor: pointer;
  }
}
