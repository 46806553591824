@import "./LoginAntd.scss";

@import "../../../shared/styles/index.scss";

.login-form {
  display: grid;
  place-items: center;

  form {
    padding: 2rem 1rem;
    margin: auto;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: $box-shadow;

    .login-form-heading {
      margin-bottom: 1rem;
      margin-right: auto;

      h3 {
        color: $main-grey-color;
        text-align: start;
        font-weight: 800;
      }

      p {
        text-align: start;
        margin-bottom: 0;
        color: #505050;
        font-weight: 500;
      }
    }

    button {
      color: #fff;
      background: $main-color;
    }

    .submit-message {
      width: 100%;
      @include setFlex($align: center, $justify: center);

      a {
        font-weight: 600;
        color: $main-color;
        margin-left: 0.25rem;
      }
    }

    .ant-form-item-control-input {
      position: relative;

      img {
        position: absolute;
        right: 1rem;
        z-index: 1000;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .ant-input-affix-wrapper {
      display: flex;
      align-items: center;

      &::before {
        display: none;
      }

      & > input.ant-input {
        padding-left: 0;
      }
    }
  }
}
