@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

.show-profile-btn {
  z-index: 11;
  position: fixed;
  bottom: 139px;
  width: 168px;
  padding: 0.9rem 0rem !important;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  vertical-align: middle;
  background-color: #e5eef8 !important;
  color: #2660bd !important;
  font-size: 1.01rem;
  font-family: inherit;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
  letter-spacing: 0.04rem;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease-in-out;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
  font-size: 16px;
  width: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ant-spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.ant-layout {
  @media (max-width: 850px) {
    background-color: #fff;
    height: 100% !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  font-weight: 300;
}

/* Buttons */

.ant-btn.primary-button {
  height: auto;
  min-width: 130px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px;
  border: 0;
  background-color: #2058e5;
  border-radius: 4px;

  &:disabled {
    background: #f5f5f5;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 850px) {
    min-width: 98px;
  }
}

.ant-btn.secondary-button {
  color: #2058e5;
  height: auto;
  min-width: 130px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 11px 19px;
  border: 1px solid #2058e5;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 850px) {
    min-width: 98px;
  }
}

/* End Buttons */

/* Cards */

.ant-card {
  font-size: 16px;
}

/* End Cards */

/* Input */

.ant-input {
  font-size: 16px;
  line-height: 24px;
  padding: 7px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;

  &:disabled {
    border: 1px solid rgb(217, 217, 217);
  }
}

.ant-input-affix-wrapper {
  font-size: 16px;
  line-height: 24px;
  padding: 7px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;

  &:disabled {
    border: 1px solid rgb(217, 217, 217);
  }

  & > input.ant-input {
    padding-left: 10px;
  }
}

.ant-form-item-label > label {
  height: 40px;
  font-size: 16px;

  @media (max-width: 850px) {
    height: 30px;
    padding-bottom: 0;
  }
}

.ant-form-large .ant-form-item-label > label {
  @media (max-width: 850px) {
    height: 30px;
  }
}

.ant-form-item .ant-form-item-label {
  @media (max-width: 850px) {
    padding: 0;
  }

  @media (max-width: 575px) {
    padding: 0;
  }
}
.ant-modal-centered .ant-modal {
  @media (max-width: 575px) {
    min-width: 80%;
    width: 80% !important;
  }
}
.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 10px;
}

/* End Input */

/* Select */

.ant-select {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 16px;
  line-height: 24px;
  padding: 1px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;
  height: 40px;
}

.ant-select-selector {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;
  height: 40px;
}
.right-side .ant-select-selector {
  height: 34px !important;
}
/* End Select */

/* Phone Input */

.react-tel-input {
  .form-control {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #dce6f7;

    &:disabled {
      border: 1px solid #d9d9d9;
      background-color: #f5f5f5;

      & + .flag-dropdown {
        border: 1px solid #d9d9d9;
        background-color: #f5f5f5;
      }
    }
  }

  .flag-dropdown {
    background-color: #fff;
    border: 1px solid #dce6f7;
  }
}

/* End Phone Input */

/* Datepicker */

.ant-picker {
  width: 100%;
  border-radius: 4px;
  padding: 8px 11px;
}

/* End Datepicker */

/* Dropdown */

.ant-dropdown {
  font-size: 16px;
}

.ant-dropdown-menu-item {
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #fff;
    background-color: #2058e5;

    & > a {
      color: #fff;
    }
  }

  & > a {
    padding: 9px 20px;
  }
}

/* End Dropdown */

/* Table */

.ant-table {
  font-size: 16px;
}

/* End Table */

/* Upload */

.ant-upload.ant-upload-drag .ant-upload {
  padding: 43px 0;
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  text-align: left;
}

/* End Upload */

/* Breadcrumb */

.ant-breadcrumb {
  text-align: left;
  margin: 20px 0;
}

/* End Breadcrumb */
.ant-table-content {
  overflow-x: auto;
}
.ant-tag {
  font-size: 14px;
  box-shadow: 0px 0px 5px #c4c4c426;
  border-radius: 14px;
  padding: 4px 16px;
}
.ant-tag-green {
  color: #269a43;
  background: #269a431a;
  border: none;
}
.ant-tag-yellow {
  color: #f98700;
  background: #f987001a;
  border: none;
}
.ant-tag-volcano {
  color: #cd2c2b;
  background: #cd2c2b1a;
  border: none;
}
.ant-table-tbody > tr > td {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
}
thead tr th.ant-table-cell {
  color: #1a1c1e;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
}
.ant-pagination-item-active a {
  background-color: #1a1c1e;
  color: #ffffff;
}
.ant-pagination-item-active {
  border-color: #1a1c1e;
}
.err {
  color: #cd2c2b;
}

.conatiner-fluid {
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: auto;
}
