@import "../../../shared/styles/index.scss";

.mint__form {
  .form__conclusion {
    @include setFlex();
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid #e3e3e3;

    &.responsive-md {
      .form__col {
        height: 90px;

        span {
          font-size: 0.75rem !important;
        }
      }
    }

    .form__col {
      width: 50%;
      display: grid;
      grid-template-rows: repeat(2, 1fr);

      & > div {
        text-align: start;
      }

      .conclusion__rate {
        & > span {
          font-weight: 600;
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
          display: inline-block;
        }

        .rate__content {
          border: 1px solid $main-color;
          color: $main-color;
          border-radius: $border-radius;
          padding: 0.4rem 0.4rem 0.4rem 0.8rem;
          font-weight: 600;
          font-size: 0.8rem;
          flex: 0.5;
          text-align: start;
          background-color: #0054b80d;
        }

        span {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }

      .tranfer-type {
        margin-top: 0.5rem;
        position: relative;

        @include setFlex($direction: column);

        span {
          font-weight: 600;
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
          display: inline-block;
        }

        & > div {
          width: 100%;
          @include setFlex($align: center, $justify: space-between);

          & > div {
            cursor: pointer;
            flex: 0.5;

            &:first-child {
              margin-right: 0.5rem;
              background-color: #0054b80d;
            }
          }
        }

        .type {
          padding: 0.5rem 0.5rem 0.5rem 0.8rem;
          background-color: #f8f8f8;
          border: 1px solid #e1e1e1;
          border-radius: $border-radius;
          display: grid;
          text-align: center;

          img {
            width: 10px;
            object-fit: contain;
          }

          &.selected {
            position: relative;
            border: 1px solid $main-color;
            font-size: 0.8rem;
            text-align: center;
            color: $main-color;
          }

          span {
            margin: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
