@import "../../../shared/styles/index.scss";

.privacy-policy {
  border-radius: $border-radius;
  box-shadow: 0px 10px 20px #c4c4c426;

  .privacy-policy-heading {
    margin-bottom: 0.5rem;
    width: 100%;
    @include setFlex($justify: space-between, $align: center);
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: no-wrap;
    flex: 0 1 auto;
    background-color: #fff;
    position: absolute;
    height: 65px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    top: 0;
    padding: 0 37px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ebebeb;
    @include setFlex($justify: space-between, $align: center);

    h3 {
      margin: 0;
    }
  }

  .privacy-policy-content {
    margin-top: 1rem;
    height: 620px;
    overflow: scroll;
    padding: 0 37px;
    text-align: start;
    margin-top: 78px;

    .content-box {
      margin-bottom: 1rem;

      h4 {
        font-weight: 900;
        margin-bottom: 0.5rem;
      }

      p {
        color: #323435;
        font-weight: 500;
      }
    }
  }
}

.ant-modal {
  &.privacy-policy-popup,
  &.terms-of-use-popup {
    width: 970px;
    max-width: 1000px;
    min-width: 960px;
    height: 700px;
    min-height: 500px;
    max-height: 720px;

    .ant-modal-content {
      border-radius: 8px;
      overflow: hidden;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      padding: 0;

      .ant-modal-body {
        margin: 0;

        overflow: hidden;

        margin: 0;
      }

      .ant-modal-footer {
        text-align: end;
        padding: 1.25rem 0;
        position: absolute;
        width: 100%;
        left: 0;
        background-color: #fff;
        bottom: 0;

        text-align: end;
        padding: 1.25rem 19px;
        .ant-btn.ant-btn-primary {
          background-color: $main-color;
        }
      }
    }
  }

  &.terms-of-use-popup {
    .privacy-policy-heading {
      flex-direction: column;

      .heading-top {
        margin-bottom: 0.5rem;
        width: 100%;
        @include setFlex($justify: space-between, $align: center);
        margin-bottom: 0.5rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: no-wrap;
        flex: 0 1 auto;
        background-color: #fff;
        position: absolute;
        height: 65px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        top: 0;
        padding: 0 37px;
      }

      p {
        text-align: start;
        color: #323435;
        font-weight: 500;
      }
    }
  }
}
