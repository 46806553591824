@import "./index.scss";

.dashboard-actions-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 1000;
  padding: 1rem;
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: $border-radius $border-radius 0 0;

  @include setFlex($direction: column);

  .dashboard-modal-heading {
    width: 100%;
    @include setFlex($align: center, $justify: space-between);
  }

  .dashboard-modal-body {
    @include setFlex($direction: column, $flex: 1, $justify: center);

    a {
      color: #000;
      font-size: 1.2rem;
      font-weight: 600;

      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }
}
