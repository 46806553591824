.upload-kyc-wrap {
  padding-top: 120px;
  height: 100%;
  margin: 0 8.33333333%;

  @media (max-width: 850px) {
    padding-top: 58px;
    margin: 0 16px;
  }

  .ant-space-vertical {
    width: 100%;
  }

  h2 {
    font-size: 48px;
    line-height: 72px;
    margin-bottom: -8px;

    @media (max-width: 850px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.02em;
      color: #2058e5;
      text-align: left;
      margin-bottom: 17px;
    }
  }

  h3 {
    @media (max-width: 850px) {
      text-align: left;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  .ant-dropdown-trigger {
    @media (max-width: 850px) {
      text-align: left;
      display: block;
      width: 100%;
      border: 1px solid #dce6f7;
      border-radius: 3px;
      padding: 8px 10px;
      font-size: 16px;
      line-height: 24px;
      color: #7e838d;
      position: relative;

      .anticon {
        position: absolute;
        top: 16px;
        right: 10px;
      }
    }
  }

  .upload-kyc-desc {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: -8px;

    @media (max-width: 850px) {
      font-size: 16px;
      line-height: 24px;
      color: #7e838d;
      margin-bottom: 46px;
      text-align: left;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text.upload-devider {
    @media (max-width: 850px) {
      display: none;
    }
  }

  .ant-divider-inner-text {
    font-weight: 600;
    font-size: 18px;
  }

  .upload-kyc-settings {
    @media (max-width: 850px) {
      width: 100%;
    }
  }

  .upload-drag-wrap {
    @media (max-width: 850px) {
      width: 100%;
    }
  }

  .finish-kyc-checkbox {
    margin-top: 10px;
    display: flex;

    @media (max-width: 850px) {
      text-align: left;
      margin-top: 40px;
      font-size: 16px;
      line-height: 24px;
    }

    .ant-checkbox-wrapper {
      margin-right: 16px;
    }
  }

  .kyc-finish-note {
    margin-top: 10px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: 850px) {
      font-size: 12px;
      line-height: 20px;
      color: #7e838d;
    }
  }

  .primary-button {
    margin-bottom: 50px;
  }
}
