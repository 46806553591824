@import '../../../shared/styles/index.scss';

$linear-transition: all .25s linear;

.transfer__showacase {
    .available-balance {
        @include setFlex($direction: column);
        padding: 1rem;
        background-color: #fff;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        margin-bottom: 1rem;

        .content {
            h4 {
                font-weight: 600;
                font-size: 15px;
                color: $secondary-grey-color;
                text-align: start;
                margin-top: 1rem;
            }

            span {
                font-weight: 800;
                font-size: 1.5rem;
            }
        }
    }

    .select-account {
        background-color: #fff;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 1rem;

        .selection__container {
            margin-top: .8rem;
            @include setFlex($justify: space-evenly, $align: center);
        }

        .transfer__bankAccount {
            position: relative;
            background: #F3F3F3;
            border: 1px solid #E9E9E9;
            border-radius: $border-radius;
            padding: 1rem;
            margin: 0 .5rem;
            cursor: pointer;
            transition: $linear-transition;
            @include setFlex($direction:column, $align: flex-start, $flex:.333);

            .anticon.anticon-check-circle {
                position: absolute;
                top: 0.25rem;
                right: .25rem;
            }

            h2 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }

            h3 {
                font-size: 13px;
            }

            p {
                font-weight: 500;
                font-size: 13px;
            }

            &.selected {
                border: 1px solid $main-color;
                background: #0054B81A;
            }
        }

        .showcase__options {
            text-align: end;
            margin-top: 1rem;

            button {
                transition: $linear-transition;

                &.clickable {
                    background: $main-color;
                }
            }
        }

        &.responsive-md {
            max-width: 96vw;

            .selection__container {
                width: 100%;
                overflow-x: auto;
                // margin: 1rem;
                flex-direction: column;
               
               // @include setFlex($justify: flex-start, $align: center);
                .transfer__box {
                    width: 100%;
                    margin: 7.5px 0;
                }
            }
        }
    }
}