@import '../../../shared/styles/index.scss';

.transactions {
    padding: 1rem 0;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    display: flex;
    width: 100%;
    h4 {
        font-weight: 800;
        text-align: start;
        color: $main-color;
    }

    .transactions-header {
        padding: 1rem .5rem;
        background-color: #fff;
        @include setFlex($justify: space-between, $align: center);

        .transactions-header-options {
            padding: .5rem;
            background-color: #ECECEC;
            border-radius: $border-radius;

            ul {
                margin-bottom: 0;
                @include setFlex($justify: space-between, $align: center);

                li {
                    @include setFlex($justify: center, $align: center);

                    padding: .5rem 1rem;
                    background-color: transparent;
                    border-radius: $border-radius;
                    cursor: pointer;
                    font-size: .75rem;
                    color: #606060;
                    font-weight: 600;

                    &.active {
                        color: #fff;
                        font-weight: 800;
                        background-color: #010F2A;
                    }

                }
            }
        }
    }

    .transactions__grid {
        .transactions__header.transactions__row {
            background-color: $main-bg;

            ul {
                li {
                    font-size: .8rem;
                    font-weight: 600;
                    color: #000;
                }
            }
        }

        .transactions__row {
            padding: .5rem 0 .5rem .5rem;
            border-bottom: 1px solid $light-grey;
            box-shadow: $box-shadow;

            ul {
                display: grid;
                margin-bottom: 0;
                margin: 0;
                grid-template-columns: repeat(4, 1fr);

                li {
                    &:not(.transactions__header ul li) {
                        font-weight: 600;
                        color: $grey-color;
                    }

                    &:not(:last-child) {
                        @include setFlex($align: center, $justify: flex-start);
                    }

                    &.transaction__name {
                        @include setFlex($direction: column);

                        span {
                            &:last-child {
                                font-weight: normal;
                            }
                        }
                    }

                    &.transaction__actions {
                        button {
                            margin-right: .5rem;
                            padding: .25rem 1.2rem;
                            font-weight: 600;
                            border-radius: $border-radius;
                            background: $main-color;

                            &:first-child {
                                a {
                                    color: #fff;
                                }
                            }

                            &:last-child {
                                color: $main-color;
                                background: transparent;
                                border: 1px solid $main-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // responsive view
    &.responsive-md {
        .transactions-header {
            @include setFlex($direction: column);

            &>div {
                width: 100%;
            }

            .transactions-header-top {
                padding: 1rem .5rem;
                margin-bottom: .5rem;
                @include setFlex($justify: space-between, $align: center);

                img {
                    width: 32px;
                    object-fit: contain;
                }
            }
        }
    }
}