@import '../../../shared/styles/index.scss';

.transactions-sub-header {
    margin-top: 1rem;
    width: 100%;
    @include setFlex ($justify: space-between, $align: center);

    .searchBox {
        flex: 1;
        margin-right: .8rem;
    }

    .input-field {
        border: none;
        outline: none;
        background-color: #F8F8F8;
        border: 1px solid #E2E2E2;
        border-radius: $border-radius;
        padding: .4rem .8rem;
        width: 100%;
        // margin-top: .25rem;
        @include setFlex($align: center);

        img {
            margin-right: .5rem;
        }

        input {
            background: transparent;
            border: none;
            outline: none;
        }
    }

    .right-side {
        @include setFlex($align: center);

        &>* {
            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

        .ant-select.ant-select-single.ant-select-show-arrow {
            border: 1px solid #E8E8E8;
            border-radius: 5px;

            .ant-select-selector {
                background: #F3F3F3;
                font-size: .8rem;
                font-weight: 600;
            }
        }

        .options {
            @include setFlex($align: center);

            &>button {
                border-radius: $border-radius;
                cursor: pointer;
                color: #fff;
                background: $main-color;
                padding: .4rem 2.5rem;
                font-weight: 600;
            }

            .btn-reset {
                position: relative;
                color: $main-color;
                padding: .4rem 1rem;
                margin-left: .5rem;
                cursor: pointer;
                border-radius: $border-radius;
                background-color: rgba(0, 84, 184, .16);
                @include setFlex($align: center);

                img {
                    margin-right: .5rem;
                }

                button {
                    background: transparent;
                    border: none;
                    color: $main-color;
                    font-weight: 500;
                }
            }
        }
    }
}