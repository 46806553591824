.body {
  text-align: initial;
  background-color: #ececec;
  @media (max-width: 850px) {
    background-color: #ececec;
  }
}
.App {
  text-align: initial;
}
.app-header {
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  &__logo {
    padding-left: 30px;
    img {
      height: 40px;
    }
  }
}

.register-page {
  a {
    color: #2554b8;
  }
  &__content {
    padding-top: 30px;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.calendarButton.selectToday {
  display: none;
}

.auth-card {
  margin-bottom: 3rem;
  opacity: 0;
  width: 710px;
  @media (max-width: 676px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 25px #c1c1c129;
  padding: 40px;
  &__submit {
    margin-top: 30px;
  }
  &__actions {
    margin-top: 43px;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 600;
      color: #505050;

      a {
        &:link,
        &:visited,
        &:active {
          color: #0054b8;
          text-decoration: none;
        }
      }
    }
  }

  hr {
    margin: 10px 0;
    border: 1px solid #f4f2f2;
  }

  &__header {
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &__description {
    p {
      font-size: 13px;
      font-weight: 600;
      color: rgb(80 80 80 / 90%);
      margin-top: 4px;
    }
  }

  &__tabs {
    display: flex;
    align-content: center;
    position: relative;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1);
  }
  &__inputs {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s cubic-bezier(0.85, 0, 0.15, 1);
  }

  &__content {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
  }

  &__tab {
    width: 48%;
    margin: 0 1%;

    button {
      padding: 13px 20px;
      display: inline-block;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      -webkit-appearance: button;
      vertical-align: middle;
      font-size: 14px;
      font-family: inherit;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      border: none;
      user-select: none;
      letter-spacing: 0.04rem;
      display: inline-block;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.2s ease-in-out;
      width: 100%;
      position: relative;
      z-index: 4;

      &::after {
        z-index: 11;
        position: absolute;
        content: "";
        background-color: transparent;
        border: 1px solid rgba(#1a1c1e, 0.2);
        transform: scaleX(1.015) scaleY(1.064);
        border-radius: 5px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &.active {
        transition: color 0.2s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.01s,
          background-color 0.001s ease 0.1s;
        color: #fff;
        background-color: transparent;
      }
    }
  }

  &__active {
    transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1);
    background-color: #1a1c1e;
    color: #fff;
    position: relative;
    width: 49%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;

    &::after {
      z-index: 11;
      position: absolute;
      content: "";
      background-color: transparent;
      border: 1px solid rgba(#1a1c1e, 0.2);
      transform: scaleX(1.015) scaleY(1.064);

      border-radius: 5px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.form-control,
.datepicker-input {
  position: relative;
  color: #606060 !important;
  padding: 12px 18px;
  outline: none;
  background-color: #f8f8f8;
  border: 2px solid #eaeaea;
  box-shadow: 0 0.06rem 0.07rem rgb(0 0 0 / 4%);
  font-family: inherit;
  font-size: 15px;
  transition: all 0.1s ease-in-out;
  color: inherit;
  display: block;
  z-index: 2;
  width: 100%;
  border-radius: 5px;

  &-lg {
    padding: 1.58rem 1.8rem;
  }

  &:focus {
    border: 2px solid rgba(#0054b8, 0.9);
  }

  &-success {
    border: 2px solid #1b4c43 !important;
  }

  &.success {
    border: 2px solid #1b4c43 !important;
  }

  &.invalid {
    border: 2px solid #d22d3d !important;
  }
}

.select-country {
  ::placeholder {
    color: #606060;
    font-size: 14px;
  }

  &__icon {
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    position: absolute;
    right: 13px;
    &.calendar {
      &.active {
        right: 45px !important;
      }
    }
    &.active {
      right: 36px !important;
    }
    top: 52% !important;
    z-index: 33;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #606060 !important;
    }
  }
  position: relative;
  &__select {
    width: 100%;
    &.date {
      width: 260px;
    }
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    height: 0;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 0px 10px 25px #c1c1c129;
    display: none;
    z-index: 50;
    &.active {
      height: auto;
      animation: animateDropDown 0.2s cubic-bezier(0.33, 1, 0.68, 1) 0.3s backwards;
      opacity: 1;
      visibility: visible;
      overflow-x: hidden;
      display: block;
    }
    li {
      padding: 10px;
      color: #606060;
      border: 1px solid rgba(#1a1c1e, 0.035);
      cursor: pointer;
      &:hover {
        background-color: #efefef;
        color: #1a1c1e;
      }
    }
  }
}

@keyframes animateDropDown {
  from {
    transform: translateY(100px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-message {
  text-align: center;
  margin-top: 20px;
  p {
    font-size: 14px;
    font-weight: 600;
    color: #505050;
  }
}
.login-auth {
  .info-icon {
    display: none !important;
  }
}

.info-icon {
  position: absolute;
  z-index: 11;
  right: -11px;
  top: -13px;
}

.forget-password {
  position: absolute;
  right: 42px;
  font-weight: bold;
  z-index: 111;
  font-size: 14px;
  bottom: 143px;

  a {
    cursor: pointer;
    text-decoration: underline;
    color: #0054b8;
  }
}

.login-auth {
  .auth-card__submit {
    margin-top: 55px !important;
  }
}

.form-label {
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  display: inline-flex;
  text-align: left;
  transition: all 0.2s ease-out;
  background-color: #ffffff;
  transform: translateY(10px);
  position: relative;
  z-index: 3;
  left: -8px;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  text-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
  top: -13px;
  border-radius: 2px;
}

.invalid-feedback {
  color: #d22d3d;
  animation: animateFeedback 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0.1s backwards;
  font-size: 11px;
  display: block;
  text-align: left;
  margin-top: 0.1rem;
  margin-left: 0.2rem;
  text-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.06);
  position: absolute;
  text-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.form-group {
  margin: 20px 0;
  position: relative;
  svg {
    fill: #606060 !important;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    position: absolute;
    right: 13px;
    top: 56%;
    z-index: 11;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  ::placeholder {
    color: #606060;
    font-size: 14px;
  }
}
.checkbox {
  user-select: none;

  &__control {
    display: none;
  }

  &__label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: -7px;

    span {
      margin-left: 1rem;
    }
  }

  &__icon {
    cursor: pointer;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #0054b8;
    svg {
      fill: #0054b8 !important;
    }

    &.checked {
      background-color: #0054b8;
      color: #fff svg {
        animation: scaleCheckMark 0.15s cubic-bezier(0.16, 1, 0.3, 1) 0.02s backwards;
      }
      svg {
        fill: #fff !important;
      }
    }
  }
}

@keyframes scaleCheckMark {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animateFeedback {
  from {
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.btn {
  padding: 12px 20px;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  vertical-align: middle;
  font-size: 16px;
  font-family: inherit;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
  letter-spacing: 0.04rem;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease-in-out;
  width: 100%;
  position: relative;
  z-index: 4;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &-primary {
    background-color: #0054b8;
    color: #fff;
  }
}
.valid-icon {
  top: 53%;
  position: absolute;
  right: 0;

  svg {
    fill: #1b4c43 !important;
  }
}

.invalid-icon {
  top: 53%;
  position: absolute;
  right: 0;
  z-index: 22;
  svg {
    fill: #d22d3d !important;
  }
}
.show-password {
  &.error {
    svg {
      right: 43px !important;
    }
  }
}

.show-password {
  &.success {
    svg {
      right: 43px !important;
    }
  }
}

.invalid-icon-calendar {
  top: 51%;
  position: absolute;
  right: 13px;
  z-index: 22;
  svg {
    fill: #d22d3d !important;
  }
}

.valid-icon-calendar {
  top: 51%;
  position: absolute;
  right: 13px;
  z-index: 22;

  svg {
    fill: #1b4c43 !important;
  }
}

.invalid-icon-country {
  top: 52%;
  position: absolute;
  right: 10px;
  z-index: 22;
  svg {
    fill: #d22d3d !important;
  }
}

.valid-icon-country {
  top: 52%;
  position: absolute;
  right: 10px;
  z-index: 22;

  svg {
    fill: #1b4c43 !important;
  }
}
