@import "./RedeemConclusionRate.scss";
@import "./RedeemForm.scss";

@import "../../../shared/styles/index.scss";

.mint__screen {
  margin-top: 0.8rem;
  padding: 0.6rem;

  &.redeem {
    background-color: transparent !important;
  }

  .back-link {
    @include setFlex($align: center);

    img {
      width: 18px;
      object-fit: contain;
    }

    span {
      font-size: 1rem;
      margin-left: 0.5rem;
      color: #000;
    }
  }

  .redeem-body {
    margin-top: 1rem;
    padding: 0.8rem;
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  h4 {
    border-bottom: 1px solid $grey-bg;
    text-align: start;
    font-weight: 600;
    padding: 0.5rem 0;
  }

  .mint__warning {
    @include setFlex($direction: column);
    background: #a881000d;
    border: 1px solid #a881001a;
    color: $warning-color;

    h5 {
      color: $warning-color;
      font-weight: 600;
      margin-left: -0.5rem;
    }

    ul {
      li {
        text-align: start;
        font-size: 0.75rem;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -8px;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $warning-color;
        }

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
