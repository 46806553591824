@import '../../../shared/styles/index.scss';

.transfer__form {
    padding: 1rem;
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .form__col {
        &:first-child {
            margin-right: 1rem;
        }
    }

    form {
        margin-top: .8rem;

        .form__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .input__field {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                @include setFlex($direction: column);

                label {
                    font-size: 14px;
                    font-weight: 600;
                    color: #303030;
                }

                input {
                    border: none;
                    outline: none;
                    background-color: #F8F8F8;
                    border: 1px solid #E2E2E2;
                    border-radius: $border-radius;
                    padding: .5rem .8rem;
                    width: 100%;
                    // margin-top: .25rem;
                }

                .ant-select.ant-select-single.ant-select-show-arrow {
                    width: 100% !important;
                }

                .ant-select-selection-item {
                    display: inline-block;
                    text-align: start;
                    color: $placeholder-grey-color;
                    font-size: .9rem;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    padding: .25rem .8rem;
                    margin-top: .25rem;
                }
            }
        }
    }

    .form-submit {
        text-align: end;
        margin-top: 1rem;
    }
}